var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      staticStyle: { position: "fixed", top: "0" },
      attrs: {
        color: _vm.color,
        timeout: _vm.overrideTimeout ? _vm.overrideTimeout : _vm.timeout,
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { dark: "", color: "white", text: "" },
                    on: {
                      click: function ($event) {
                        _vm.shown = false
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v("Close")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.shown,
        callback: function ($$v) {
          _vm.shown = $$v
        },
        expression: "shown",
      },
    },
    [_vm._v(" " + _vm._s(_vm.text) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }