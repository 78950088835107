<template>
    <v-snackbar style="position: fixed; top:0" v-model="shown" :color="color" :timeout="overrideTimeout ? overrideTimeout : timeout">
        {{ text }}
        <template v-slot:action="{ attrs }">
            <v-btn dark color="white" text v-bind="attrs" @click="shown=false">Close</v-btn>
        </template>
    </v-snackbar>
</template>
<script>
export default {
    data: () => ({
        shown: false,
        text: "",
        color: "",
        overrideTimeout: null,
    }),
    props: {
        timeout: {
            default: 3000
        }
    },
    methods: {
        show(color, text, timeout=null){
            this.shown = false;
            this.$nextTick(() => {
                this.text = text;
                this.color = color;
                this.overrideTimeout = timeout ? timeout : null;
                this.shown = true;
            });
        },
        hide() {
            this.shown = false;
        }
    }
}
</script>
